declare global {
  interface Window {
    ga: Function;
  }
}

interface GoogleAnalyticsEvent {
  category: string;
  action: string;
  label: string;
  code: string;
}

class GoogleAnalyticsService {
  private readonly isEnabled: boolean;

  public constructor() {
    this.isEnabled = window.ga && typeof window.ga === 'function';
  }

  public sendEvent = (event: GoogleAnalyticsEvent): void => {
    if (!this.isEnabled) {
      return;
    }

    const { category, action, label } = event;
    window.ga('send', 'event', category, action, label);
  };
}

export default GoogleAnalyticsService;
