import { callParentIFrame, isEmbedFrame } from '../js_helpers/embed_frame_helpers';
import { getQueryParams, setQueryParam } from '../js_helpers/query_string_helpers';
import debounce from 'lodash.debounce';
import hubEvents from './hub_events';

declare global {
  interface Window {
    parentIFrame: ParentIFrame;
    g_iFrameLoadDataCallback: Function;
    g_iFrameScrollCallback: Function;
  }
}

const publishScrollEvent = debounce((eventDictionary) =>
  hubEvents.publish('scroll', eventDictionary),
);

class EmbedFrameMessenger {
  private readonly pageBookmark = document.body.dataset.pageBookmark || '';

  private resetScrollTop: number | 'top' = 0;

  private currentScrollTop: number = 0;

  public constructor() {
    if (isEmbedFrame()) {
      this.init();
    }
  }

  private init = (): void => {
    this.initIFrameResizer();
    this.initParentHashBookmark();
    this.initParentScrollPosition();
    hubEvents.subscribe('scroll', this.handleScroll);
    hubEvents.subscribe('unload', this.handleUnload);
  };

  /**
   * Setup global functions that are expected by `iframeResizer.contentWindow.js`.
   * The `g_iFrameScrollCallback` function is used to broadcast an `uberflip.scroll`
   * event. That event is used by several components to update when the Hub is
   * loaded within an iFrame Embed.
   *
   * Once the global functions are setup, we post the `Hubs.Initialized` message
   * so that the parent window can run initialization procedures.
   */
  private initIFrameResizer = (): void => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    window.g_iFrameScrollCallback = (
      scrollTop: number,
      offsetTop: number,
      viewportX: number,
      viewportY: number,
    ) =>
      publishScrollEvent({
        detail: { isEmbedFrame: true, offsetTop, scrollTop, viewportX, viewportY },
      });

    // eslint-disable-next-line @typescript-eslint/camelcase
    window.g_iFrameLoadDataCallback = () => {};

    window.parent.postMessage('Hubs.Initialized', '*');
  };

  private initParentHashBookmark = (): void => {
    const hashFragment =
      this.pageBookmark.indexOf('#') === 0 ? this.pageBookmark.substring(1) : this.pageBookmark;
    callParentIFrame(() => window.parentIFrame.updateUrl(hashFragment));
  };

  private initParentScrollPosition = (): void => {
    const { scrollTop } = getQueryParams();
    if (!scrollTop) return;
    this.resetScrollTop = scrollTop === 'top' ? 'top' : parseFloat(scrollTop);
  };

  private handleScroll = (event: Event): void => {
    const { detail } = event as CustomEvent;
    if (!detail) return;

    const { scrollTop, offsetTop } = detail;
    this.currentScrollTop = scrollTop;
    this.checkResetScrollTop(scrollTop, offsetTop);
  };

  /**
   * When iFrame Embed encounters a page change, check if we need to scroll the
   * iFrame into position. A new page should scroll to 'top' (which is equal-to
   * offsetTop position). While clicking Back/Forward browser button, should
   * reset to the last-known scrollTop position on that page.
   *
   * @param scrollTop: current scroll position in parent window
   * @param offsetTop: position of iFrame top in parent page
   */
  private checkResetScrollTop = (scrollTop: number, offsetTop: number): void => {
    if (!this.resetScrollTop) return;

    const isScrollRequired =
      typeof this.resetScrollTop === 'number' ||
      (this.resetScrollTop === 'top' && scrollTop > offsetTop);
    if (isScrollRequired) {
      callParentIFrame(() => window.parentIFrame.scroll(this.resetScrollTop));
    }

    this.resetScrollTop = 0;
  };

  /**
   * When visitor is leaving the current page, persist the scroll-top position on
   * the Current URL. So if the visitor returns using the browser's Back/Forward
   * buttons, the iFrame will continue from the last-known scroll position.
   */
  private handleUnload = (): void => {
    setQueryParam('scrollTop', this.currentScrollTop.toString());
  };
}

export default EmbedFrameMessenger;
