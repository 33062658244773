import { UF_MOBILE_BREAKPOINT, UF_TABLE_BREAKPOINT } from '../uf_constants';

export function isMobileBreakpoint(): boolean {
  return window.matchMedia(UF_MOBILE_BREAKPOINT).matches;
}

export function isTableBreakpoint(): boolean {
  return window.matchMedia(UF_TABLE_BREAKPOINT).matches;
}

export function htmlStringToElement(html: string) {
  const removeNewlinesRegex = /\r?\n|\r/g;
  const cleanHtml = html.replace(removeNewlinesRegex, '');
  const doc = new DOMParser().parseFromString(cleanHtml, 'text/html');
  return Array.from(doc.body.childNodes) as HTMLElement[];
}

export function sanitizeStringHtml(value: string) {
  /* eslint-disable sort-keys */
  const map: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  };
  /* eslint-enable sort-keys */

  const reg = /[&<>"'/]/gi;
  return value.replace(reg, (match) => map[match]);
}

export function getCookie(name: string): string | null {
  const matched = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  const cookieValue = matched ? (matched[2] || '').toString() : null;
  return cookieValue ? decodeURIComponent(cookieValue) : null;
}

export function setCookie(
  name: string | null,
  value: string | number | null = '',
  expiryDays: number = 365,
  path: string = '/',
  isSecure: boolean = false,
): void {
  if (!name) return;

  const cookieName = encodeURIComponent(name.toString());
  const cookieValue = encodeURIComponent((value || '').toString());
  const cookiePath = `; path=${path}`;
  const cookieSecure = isSecure ? '; secure' : '';

  let cookieExpires = '';
  if (expiryDays) {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + expiryDays);
    cookieExpires = `; expires=${expiryDate.toUTCString()}`;
  }

  document.cookie = `${cookieName}=${cookieValue}${cookieExpires}${cookiePath}${cookieSecure}`;
}

export function getTruthy(
  value: string | undefined | null,
  defaultValue: boolean = false,
): boolean {
  return value === undefined || value === null
    ? defaultValue
    : ['true', true, '1', 1].indexOf(value) !== -1;
}

export function getNumber(value: string | undefined | null, defaultValue: number = 0): number {
  return value === undefined || value === null ? defaultValue : parseInt(value, 10);
}
