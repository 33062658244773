import { getQueryParams } from './query_string_helpers';
import { getTruthy } from './dom_helpers';

function isFrameWindow(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isEmbedFrame(): boolean {
  if (!isFrameWindow()) {
    return false;
  }

  const { embedded } = getQueryParams();
  return getTruthy(embedded);
}

export function callParentIFrame(callback: Function): void {
  const isFrameMessengerReady = 'parentIFrame' in window;
  if (isFrameMessengerReady) {
    callback();
  } else {
    window.addEventListener('parentIFrameReady', () => callback());
  }
}
