/* eslint-disable guard-for-in, no-restricted-syntax */
import { getCookie, setCookie } from '../js_helpers/dom_helpers';
import hubEvents from '../hub_events/hub_events';

class PrivacyPreferenceService {
  private readonly FUNCTIONALITY_CODE_UFA = 'UBERFLIP';

  private readonly COOKIE_NAME = 'uf_privacy_prefs';

  private readonly NO_PRIVACY_BANNER = 0;

  private cookieFormatVersion: number = 1;

  public bannerState: number = this.NO_PRIVACY_BANNER;

  public constructor() {
    this.initState();
  }

  private initState = (): void => {
    const encodedString = getCookie(this.COOKIE_NAME);
    if (!encodedString) return;
    this.parseCookie(encodedString);
  };

  private parseCookie = (delimitedCookie: string): void => {
    const decodedCookie = delimitedCookie.split('|');
    this.cookieFormatVersion = parseInt(decodedCookie.shift() || '', 10);
    this.bannerState = parseInt(decodedCookie.shift() || '', 10);
  };

  private stringifyCookie = (): string => {
    const privacyGroups = this.getAll();
    const csvPrivacyGroups = Object.keys(privacyGroups).map((id) => {
      const privacyGroup = privacyGroups[id];
      return `${id},${privacyGroup.version},${privacyGroup.isAccepted}`;
    });
    return `${this.cookieFormatVersion}|${this.bannerState}|${csvPrivacyGroups.join('|')}`;
  };

  private cleanId = (id: number | string): number => parseInt(String(id), 10);

  /**
   * Updated Privacy Group "Accepted" value. And if required, trigger UFA events.
   *
   * @param privacyGroupId
   * @param isAccepted
   */
  private updateById = (privacyGroupId: number | string, isAccepted: boolean): void => {
    const id = this.cleanId(privacyGroupId);
    const privacyGroup = window.uberflip.privacyGroups[id];
    if (!privacyGroup) return;

    const isAcceptedValue = isAccepted ? 1 : 0;
    const noValueChange = privacyGroup.isAccepted === isAcceptedValue;
    if (noValueChange) return;

    privacyGroup.isAccepted = isAcceptedValue;

    (privacyGroup.functionalities || []).forEach((functionality: PrivacyFunctionality) => {
      if (functionality.code === this.FUNCTIONALITY_CODE_UFA) {
        const eventName = isAccepted ? 'ufaOptIn' : 'ufaOptOut';
        hubEvents.publish(eventName);
      }
    });
  };

  // Public
  // ---

  /**
   * Check if all Privacy Groups are "Accepted". Used to check permissions when
   * persisting Query Params in storage.
   */
  public isTrackingAllowed = (): boolean =>
    Object.values(this.getAll()).every(({ isAccepted }) => isAccepted !== 0);

  /**
   * Used to dismiss the Privacy Banner so it will not appear again for the current
   * visitor. The action is saved to cookie.
   */
  public dismissBanner = (): void => {
    this.bannerState = this.NO_PRIVACY_BANNER;
    this.saveChanges();
  };

  /**
   * Returns keyed-list of Privacy Groups on this Hub, including current `isAccepted`
   * status respective of the current visitor. Each item contains Privacy Group object.
   *
   * @returns PrivacyGroups
   */
  public getAll = (): PrivacyGroups => Object.assign({}, window.uberflip.privacyGroups);

  /**
   * Get a Privacy Group using the Privacy Group's id.
   *
   * @param privacyGroupId: eg. 308110
   * @returns PrivacyGroup | null
   */
  public getById = (privacyGroupId: string | number): PrivacyGroup | null => {
    const id = this.cleanId(privacyGroupId);
    return this.getAll()[id] || null;
  };

  /**
   * Get a Privacy Group based on the Functionality code (eg. `UBERFLIP`).
   *
   * @param functionalityCode: eg. 'uberflip', 'UBERFLIPUI', etc (case-insensitive)
   * @returns PrivacyGroup | null
   */
  public getByFunctionalityCode = (functionalityCode: string): PrivacyGroup | null => {
    if (!functionalityCode) return null;

    const privacyGroups = this.getAll();

    for (const id in privacyGroups) {
      const privacyGroup = privacyGroups[id];
      const { functionalities } = privacyGroup;

      if (functionalities) {
        for (const key in functionalities) {
          const functionality = functionalities[this.cleanId(key)];
          if (functionalityCode.toLowerCase() === functionality.code.toLowerCase()) {
            return privacyGroup;
          }
        }
      }
    }
    return null;
  };

  /**
   * Check if a Privacy Group belonging to Functionality (eg. `UBERFLIP`) has been
   * "Accepted" by the visitor.
   *
   * If the Functionality is not linked to a Privacy Group, it is treated as being
   * Enabled.
   *
   * @param functionalityCode: eg. UBERFLIP, UBERFLIPUI, etc
   * @returns boolean
   */
  public isFunctionalityEnabled = (functionalityCode: string): boolean => {
    const privacyGroup = this.getByFunctionalityCode(functionalityCode);
    return privacyGroup ? Boolean(privacyGroup.isAccepted) : true;
  };

  /**
   * Set all Privacy Groups as "Accepted" for this current visitor.
   */
  public acceptAll = (): void => {
    const privacyGroupIds = Object.keys(this.getAll());
    privacyGroupIds.forEach((privacyGroupId) => this.updateById(privacyGroupId, true));
    this.saveChanges();
  };

  /**
   * Set all Privacy Groups as "Rejected" for this current visitor.
   */
  public rejectAll = (): void => {
    const privacyGroupIds = Object.keys(this.getAll());
    privacyGroupIds.forEach((privacyGroupId) => this.updateById(privacyGroupId, false));
    this.saveChanges();
  };

  /**
   * Set a specific Privacy Group as "Accepted" for this current visitor.
   */
  public acceptById = (privacyGroupId: string | number): void => {
    this.updateById(privacyGroupId, true);
    this.saveChanges();
  };

  /**
   * Set a specific Privacy Group as "Rejected" for this current visitor.
   */
  public rejectById = (privacyGroupId: number | string): void => {
    this.updateById(privacyGroupId, false);
    this.saveChanges();
  };

  /**
   * Save `isAccepted` changes to the Privacy Preferences cookie.
   *
   * For changes to be stored, this method must be triggered after any changes
   * using the methods: acceptAll, rejectAll, acceptById, or rejectById.
   */
  public saveChanges = (): void => {
    const encodedValue = this.stringifyCookie();
    setCookie(this.COOKIE_NAME, encodedValue);
  };

  /**
   * Function that must be triggered once all cookie changes have been made. It will
   * reload the page so that Hub elements are rendered with the new privacy
   * behaviour (eg. stop/start tracking the visitor's activity).
   */
  public applyChanges = (): void => {
    window.location.reload();
  };
}

const privacyPreferences = new PrivacyPreferenceService();

export default privacyPreferences;
